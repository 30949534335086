<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/guanyu/jr_banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <div class="title">
                {{$t('jiaru.welfare')}}
            </div>
            <el-row style="margin-top:2%">
                <el-col :span="24" :xs="24">
                    <center class="fuwenben" v-html="pagehtml"></center>
                </el-col>
            </el-row>
            <div class="title" style="margin-top:3%">
                {{$t('search.socialRecruitment')}}
            </div>
            <div class="search">
                <el-row>
                    <el-col :span="21"> 
                        <el-input v-model="hrParams.key" placeholder=""></el-input>
                    </el-col>
                    <el-col :span="3"> 
                        <div class="cxbtn" @click="getList">
                            {{$t('inquire')}}
                        </div>
                    </el-col>
                </el-row>
                <div>
                    <el-table
                        :data="list"
                        :row-style="{fontSize:'14px',color:'#333',fontFamily:'SourceHanSansCN-Regular',fontWeight: '400','cursor':'pointer'}"
                        :header-cell-style="{'background': '#B5B4B4','fontWeight': 'bold','color':'#fff'}"
                        header-align='center'
                        @cell-click="a"
                        style="width: 100%;margin-top:2%">
                        <el-table-column prop="hr_title" :label="$t('jiaru.jobVacancy')" align='center'></el-table-column>
                        <el-table-column prop="hr_department" :label="$t('jiaru.Department')" align='center'></el-table-column>
                        <el-table-column prop="hr_address" align='center' :label="$t('jiaru.workingPlace')"></el-table-column>
                        <!-- <el-table-column prop="add_time" align='center' :label="$t('jiaru.releaseDate')"></el-table-column> -->
                    </el-table>
                    <el-pagination
                        v-show="total>10"
                        style="margin-top:3%;text-align:center"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="hrParams.page"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="hrParams.limit"
                        layout="total, prev, pager, next"
                        :pager-count="5"
                        :total="total">
                    </el-pagination>
                    <div style="margin-top:20px">联系电话:135-2683-5876 张先生</div>
                </div>
            </div>
            <el-dialog
            class="pub_dialog"
            :title="$t('jiaru.recruitmentDetails')"
            :visible.sync="dialogVisible"
            width="50%"
            >
            <div v-html="html"></div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import { hrList,hrPage} from "@/api/api"; 
export default {
    data(){
        return{
            tableData:[],
            total:0,
            hrParams:{
                page:1,
                limit:10
            },
            list:[],
            dialogVisible:false,
            html:'',
            pagehtml:'',
            title:''
        }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.keywords')
            },
            {
                name: 'description',
                content: this.$t('seo.description')
            }
        ]
        }
    },
    created(){
        this.title = this.$t('layout.join')+'-'+this.$t('seo.title')
        this.getList()
    },
    methods:{
        async getList(){
            const { data:res } = await hrList(this.hrParams)
            const { data:ress } = await hrPage()
            this.pagehtml = ress.hrpage_desc
            this.total = res.total
            this.list = res.list
        },
        handleSizeChange(value){
            this.hrParams.limit = value
            this.getList()
        },
        handleCurrentChange(value){
            this.hrParams.page = value
            this.getList()
        },
        a(data){
            this.dialogVisible = true
            this.html = data.hr_desc
        }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 70%;
        margin-top: 1%;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
        }
    }
    .title{
        text-align: center;
        font-size: 24px;
        font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        color: #333333;
    }
    .images{
        position: relative;
        max-height: 390px;
        .info{
            background: rgba(0, 0, 0, 0.4);
            height: 100%;
            position: absolute;
            top: 0px;
            width: 100%;
            .imagetitle{
                font-size: 24px;
                text-align: center;
                font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                font-weight: bold;
                color: #FFFFFF;
                margin-top: 5%;
            }
            .imagenum{
                padding-top: 15%;
                font-size: 90px;
                font-family: SourceHanSansCN-Normal;
                text-align: center;
                color: #FFFFFF;
            }
        }
        .infos{
            display: none;
            position: absolute;
            top:0px;
            width: 100%;
            height: 100%;
            .infostitle{
                font-size: 24px;
                text-align: center;
                font-family: SourceHanSansCN-Bold;
                font-weight: bold;
                color: #FFFFFF;
                margin-top: 25%;
            }
            .infoscontent{
                text-align: center;
                color: #FFFFFF;
                margin-top: 5%;
                line-height: 30px;
                font-size: 16px;
                font-family: SourceHanSansCN-Regular;
                font-weight: 400;
                color: #FFFFFF;
            }
        }
    }
    .images :hover{
        background: none;
    }
    .images:hover > .info{
        display: none;
    }
    .images:hover > .infos{
        background: rgba(0, 0, 0, 0.9);
        display: block;
    }
    .search{
        margin-top: 2%;
        .cxbtn{
            background: #0052D9;
            height: 40px;
            text-align: center;
            line-height: 40px;
            color: #fff;
        }
    }
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
</style>
<style scoped>
 /deep/ .el-dialog__body{
     padding-top: 0px;
 }
</style>
<style>
    img{
        max-width: 100% !important;
        height: auto !important;
    }
</style>